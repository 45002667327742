import React from "react";

import { useHandleFormInput } from "../../hooks.js/fetch_hooks";
import { isEmpty } from "../../utils/functions";
import { generalPost } from "../../utils/api";
import { useToasts } from "react-toast-notifications";

import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { LoaderSpinner } from "../../components/loader";
import { Overlay } from "../../components/modal";
import { useRegister } from "../../hooks.js/useRegister";

export function Register() {
	const { formData, others, handleInputChange, errors, handleFormSubmit } = useRegister();

	const { addToast } = useToasts();
	const history = useHistory();
	// const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");

	// console.log(data);
	return (
		<div className="bg-white h-screen w-screen">
			{/* {showFetchingSpinner && (
				<Overlay>
					<div className="pt-56">
						<LoaderSpinner />
						<p className="text-white text-center">
							Creating Account ......
						</p>
					</div>
					
				</Overlay>
			)} */}
			<div className="container flex flex-col">
				<nav className="flex items-center pt-5">
					<h1 className="text-3xl font-medium">LoanX</h1>
					<div className="ml-auto text-xs font-normal">
						<span className="">Have an Account? </span>
						<Link to="/login" className="ml-2 text-pink-1">
							Sign in
						</Link>
					</div>
				</nav>
				<div className="flex-1 mt-5 md:mt-10 flex flex-col md:flex-row h-full">
					<div className="md:w-6/12 mb-10 md:mb-0  md:mt-20">
						<h3 className="text-4xl font-bold ">
							Never wait for the next <br /> payday.
						</h3>
					</div>
					<div className="flex-1 md:ml-8 text-blue-1">
						<h5 className="text-base font-bold mb-6">Sign Up to use LoanX</h5>

						<form onSubmit={(e) => e.preventDefault()}>
							<div className="grid grid-cols-2 gap-3">
								<div>
									<label>First Name</label>
									<input
										type="text"
										name="first_name"
										value={formData.first_name}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.first_name && (
										<span className="font-normal text-xs text-red-500">
											{errors.first_name}
										</span>
									)}
								</div>
								<div>
									<label>Last Name</label>
									<input
										type="text"
										name="last_name"
										value={formData.last_name}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.last_name && (
										<span className="font-normal text-xs text-red-500">
											{errors.last_name}
										</span>
									)}
								</div>
								<div>
									<label>Username</label>
									<input
										type="text"
										name="username"
										value={formData.username}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.username && (
										<span className="font-normal text-xs text-red-500">
											{errors.username}
										</span>
									)}
								</div>
								<div>
									<label>Password</label>
									<input
										type="password"
										name="password"
										value={formData.password}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.password && (
										<span className="font-normal text-xs text-red-500">
											{errors.password}
										</span>
									)}
								</div>
								<div>
									<label>Email</label>
									<input
										type="text"
										name="email"
										value={formData.email}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.email && (
										<span className="font-normal text-xs text-red-500">
											{errors.email}
										</span>
									)}
								</div>
								<div>
									<label>Phone Number</label>
									<input
										name="phone"
										value={formData.phone}
										className="block h-10 rounded-lg bg-input-gray mt-1 mb-6 text-black p-3 font-normal"
										onChange={handleInputChange}
									/>
									{errors.phone && (
										<span className="font-normal text-xs text-red-500">
											{errors.phone}
										</span>
									)}
								</div>
								{/* <div>
									<label>Branch</label>
									<select
										value={formInfo.branch}
										name="branch"
										onChange={(e) => {
											setInfo(e);
										}}
										// onChange={handleFormData}
										className=" block h-10 border-2 rounded-lg bg-input-gray w-9/12 mt-1 mb-6  px-3 bg-transparent"
									>
										<option value=""></option>
										{data.map((branch) => (
											<option value={branch.id}>{branch.name}</option>
										))}
									</select>
								</div> */}
							</div>
							<div className="mb-3 mt-3 flex">
								<input
									className=" mr-2 mt-1 h-5 w-5"
									type="checkbox"
									name="agree"
									checked={formData.agree}
									onChange={handleInputChange}
								/>
								<p className="text-xs">
									Creating an account means you're okay with our{" "}
									<span className="text-black font-normal">
										Terms of Service,{" "}
									</span>{" "}
									<span className="text-black font-normal">Privacy Policy</span>{" "}
									and our default{" "}
									<span className="text-black font-normal">
										Notification Settings
									</span>
								</p>
							</div>
							{errors.agree && (
								<p className="mt-1 mb-2 font-normal text-xs text-red-500">
									{errors.agree}
								</p>
							)}
							<div className="mt-8">
								<button
									onClick={handleFormSubmit}
									className="shadow px-16 focus:outline-none text-white bg-green-400 font-semibold py-3 rounded-full"
								>
									{others.isSubmitting ? (
										<span className="spinner-spin h-6 w-6"></span>
									) : (
										"Create Account"
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
