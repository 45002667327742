import React, { useState, useContext, useEffect, useRef } from "react";

import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../context/app_context";
import CanExecute from "../../components/can-execute";

import { config } from "../../utils/constants";
import sidebar from "../../utils/sidebar";
import { handleSidebar, removeTokenFromStorage } from "../../utils/functions";

export function AppSidebar() {
	const [activeItem, setActiveItem] = useState("");
	const [drop, setDrop] = useState(false);
	const [expand, setExpand] = useState(false);

	const sidebarRef = useRef();

	const history = useHistory();
	const { setUserObject, appState } = useContext(AppContext);
	const isActive = (item) => {
		return history.location.pathname.startsWith(item.route);
	};
	const handleItemClick = (item) => {
		if (activeItem === item.name) {
			setActiveItem("");
			return;
		}
		if (item.children && item.children.length) {
			setActiveItem(item.name);
		} else {
			handleChildrenClick(item);
		}
	};
	const handleChildrenClick = (item) => {
		if (item.route) {
			history.push(item.route);
		}
	};

	const logout = () => {
		removeTokenFromStorage();

		setUserObject(null);
		history.push("/login");
	};
	console.log(appState);
	const userPermission = appState.user?.permissions.length
		? appState.user?.permissions.map((each) => {
				return each.permit_name;
		  })
		: [];

	// useEffect(() => {

	// 	const elem = sidebarRef.current
	// 	elem.addEventListener('mouseenter', () => {

	// 	})

	// 	return () => {
	// 		cleanup
	// 	}
	// }, [input])

	return (
		<nav
			ref={sidebarRef}
			// onMouseEnter={() => {
			// 	setExpand(true);
			// }}
			onMouseLeave={() => {
				setExpand(false);
			}}
			className={`relative ${
				expand ? "w-56" : "w-16 overflow-x-hidden"
			} flex-shrink-0 sidebar-bg text-white overflow-y-auto sidebar-transition`}
		>
			<img
				alt={""}
				className="fixed z-0 bottom-0 left-0 w-56 ml-5 object-contain"
				src={require("./../../assets/loanx-Pattern.svg")}
			/>

			<div className="relative z-10">
				<div className="w-11/12 mx-auto mb-10">
					{/* <h1 className="ml-2 text-3xl mt-6 font-medium">LoanX</h1> */}
					<img
						alt={""}
						className={`block mt-8 ${expand ? "w-20" : "w-10 mx-auto"} object-contain`}
						src={require("./../../assets/loanxxxx-logo.svg")}
					/>
					{/* <div className="ml-2 flex justify-between items-center mt-5 mb-6">
						<div>
							<span className="">Welcome home</span>
							<h6 className="text-sm font-bold">John Doe</h6>
						</div>
						<div className="flex items-center">
							<span className="relative">
								<span
									className="cursor-pointer text-xl font-extrabold block transform rotate-90 mr-1"
									onClick={() => setDrop(!drop)}
								>
									&rsaquo;
								</span>

								<ul
									className={
										(drop ? "drop-in" : "drop-out") +
										" drop shadow-xl absolute bg-white text-gray-1 font-normal  right-0 py-2 divide-y divide-gray-300"
									}
								>
									<li className="px-8 py-1 hover:text-black hover:font-bold">
										<Link to={"/settings"}>Settings</Link>
									</li>
									<li className="px-8 py-1 hover:text-black hover:font-bold">
										<button onClick={logout}>Logout</button>
									</li>
								</ul>
							</span>
							<img
								onClick={() => setDrop(!drop)}
								alt=""
								src=""
								className="cursor-pointer h-8 w-8 rounded-full bg-gray-200"
							/>
						</div>
					</div> */}

					<ul className="space-y-3 mt-12">
						{sidebar.sidebar_data.map((item, i) => (
							<CanExecute
								// userPermission={userPermission}
								permissions={handleSidebar(item.children)}
							>
								<li className="" key={"sidebar-item" + i}>
									<span
										onClick={() => {
											setExpand(true);
											handleItemClick(item);
										}}
										className={
											(item.name === activeItem || isActive(item)
												? "bg-white text-gray-700 rounded-full"
												: "") +
											" py-1 px-3 flex items-center cursor-pointer"
										}
									>
										<img
											className={
												(item.name === activeItem || isActive(item)
													? "invert-1 "
													: "") +
												(expand ? "mr-2" : "mx-auto") +
												" h-5 w-5"
											}
											src={item.icon}
											alt=""
										/>
										<span
											className={`text-xs ${
												isActive(item) ? "font-semibold" : "font-normal"
											} ${expand ? "" : "opacity-0 w-0"} sidebar-transition`}
										>
											{item.name}
										</span>
										{!!item.children.length && (
											<span
												className={`${
													item.name === activeItem
														? "rotate-90"
														: "rotate-0"
												} transform duration-500  transition-transform ml-auto text-lg font-semibold ${
													expand ? "" : "hidden"
												}`}
											>
												&rsaquo;
											</span>
										)}
									</span>
									{!!(item.children && item.children.length && expand) && (
										<ul
											className={
												(item.name === activeItem
													? "s-drop-in mt-3 mb-8"
													: "s-drop-out") + " s-drop ml-10  "
											}
										>
											{item.children.map((item_child, j) => (
												<CanExecute
													// userPermission={
													// 	userPermission
													// }
													key={`sub${j}`}
													permissions={item_child.permission}
												>
													<li
														onClick={() =>
															handleChildrenClick(item_child)
														}
														className={
															(history.location.pathname ===
															item_child.route
																? "font-bold"
																: "") +
															(item_child.route
																? ""
																: " opacity-25 ") +
															(expand ? "" : " hidden ") +
															" leading-tight text-xs py-2 sidebar-transition cursor-pointer"
														}
														key={"item_child" + i + j}
													>
														{item_child.name}
													</li>
												</CanExecute>
											))}
										</ul>
									)}
								</li>
							</CanExecute>
						))}
					</ul>

					<ul className="mt-32 text-right mr-6 space-y-6">
						{sidebar.bottom_sidebar_data.map((item, i) => (
							<li
								onClick={() => handleChildrenClick(item)}
								className={`${isActive(item) ? "font-bold" : "font-normal"} ${
									item.route ? "" : "opacity-50"
								} cursor-pointer`}
								key={"bottom-side-item" + i}
							>
								{item.name}
							</li>
						))}
					</ul>
				</div>
			</div>
		</nav>
	);
}
