import React from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { LoaderOverlay } from "../../components/loader";
import { useSingleState } from "../../hooks.js/useSingleState";
import { forgotPassword } from "../../utils/api-request";

const ResetPassword = () => {
	const { addToast } = useToasts();

	const submitLoading = useSingleState(false);

	const email = useSingleState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		const dataToSend = {
			email: email.use,
		};

		submitLoading.set(true);

		try {
			const response = await forgotPassword(dataToSend);
			if (response.status.toString().startsWith("2")) {
				submitLoading.set(false);
				addToast(
					"A message has been sent to your email. Kindly follow the instruction there to reset your password.",
					{
						appearance: "success",
						autoDismiss: true,
					}
				);
				email.set("");
			}
		} catch (error) {
			submitLoading.set(false);
			console.log(error);
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			} else {
				addToast("Something went wrong. Please try again", {
					appearance: "error",
					autoDismiss: true,
				});
			}
		}
	};

	return (
		<div className="bg-white w-screen md:h-screen">
			{submitLoading.use && <LoaderOverlay />}
			<div className="container flex flex-col">
				<nav className="flex items-center text-white pt-5">
					<h1 className="text-3xl force-black font-medium">LoanX</h1>
				</nav>
				<div className="flex justify-center mt-32">
					<form onSubmit={handleSubmit} className="w-96">
						<h5 className="text-center force-black font-bold mb-4 text-lg">
							Reset your password
						</h5>
						<p
							style={{
								color: "#000000",
								fontSize: "10px",
								textAlign: "center",
								fontWeight: "500",
							}}
						>
							Please enter the email you signed up with to receive instuctions on
							resetting your password
						</p>
						<div className="mt-8">
							<label className="force-black font-semibold">Email Address</label>
							<input
								className="border-2 w-full mt-2 bg-input-gray block px-3 py-3 border-gray-400"
								type="email"
								onChange={(e) => {
									email.set(e.target.value);
								}}
								value={email.use}
								// pattern="^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$"
								required
							/>
						</div>
						<div className="flex mt-6 justify-center">
							<button
								type="submit"
								// value="Send me Instructions"
								className="py-3 rounded-md text-white cursor-pointer px-6 cusBlue"
							>
								Send me Instructions
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
