import React, { useEffect, useReducer, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
	getAllLoansCustomer,
	addDebitCard,
	getCurrentRepaymentStaff,
} from "../../../utils/api-request";
const initialState = {
	store: [],
	noOfPages: 0,
	data: [],
	showFullPageLoader: false,
	loading: "IDLE",
	showContinueModal: false,
	reference: null,
	currentRepaymentModal: false,
	currentRepaymentData: {},
	showRepaymentModal: false,
	repaymentData: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "CHANGE": {
			return { ...state, ...action.payload };
		}
		default: {
			return { ...state };
		}
	}
};

const useViewLoanCustomer = () => {
	const { addToast } = useToasts();
	const [allState, dispatch] = useReducer(reducer, initialState);

	const [currentPage, setCurrentPage] = useState(1);
	const [refetch, setRefetch] = useState(true);

	const prevPage = () => {
		// console.log(state);
		if (currentPage > 1) {
			setCurrentPage((e) => e - 1);
		}
	};

	const nextPage = () => {
		if (currentPage < allState.noOfPages) {
			setCurrentPage((e) => e + 1);
		}
	};

	const refetchData = () => {
		setRefetch((e) => !e);
	};

	useEffect(() => {
		if (allState.noOfPages > 1) {
			const endIndex = currentPage * 10;
			const startIndex = endIndex - 10;
			const newData = allState.store.slice(startIndex, endIndex);
			dispatch({ type: "CHANGE", payload: { data: newData } });
		}
	}, [currentPage]);

	const fetchCurrentRepayment = async (id) => {
		dispatch({
			type: "CHANGE",
			payload: { showFullPageLoader: true },
		});
		try {
			const response = await getCurrentRepaymentStaff(id);
			console.log(response);
			dispatch({
				type: "CHANGE",
				payload: {
					currentRepaymentData: response.data.data,
					showFullPageLoader: false,
					currentRepaymentModal: true,
				},
			});

			// handleRepData(response.data.data);
		} catch (error) {
			console.log(error);
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			}
		} finally {
			dispatch({
				type: "CHANGE",
				payload: { showFullPageLoader: false },
			});
		}
	};

	const createDebitCard = async () => {
		dispatch({ type: "CHANGE", payload: { showFullPageLoader: true } });
		try {
			const response = await addDebitCard();
			if (response.status === 200) {
				// console.log(reference, authorization_url);
				dispatch({
					type: "CHANGE",
					payload: { reference: response.data.data, showContinueModal: true },
				});

				// setState((old) => {
				// 	return { ...old, reference };
				// });
				// window.location.href = authorization_url;
				// setShowContinue(true);

				// window.location.href = authorization_url;
			}
		} catch (error) {
			if (!error.response) {
				addToast("Kindly check your network and refresh", {
					appearance: "error",
					autoDismiss: true,
				});
			} else if (error.response) {
				addToast(error.response.data.errorMessage, {
					appearance: "error",
					autoDismiss: true,
				});
			} else {
				addToast("Operation failed", {
					appearance: "error",
					autoDismiss: true,
				});
			}
		} finally {
			dispatch({ type: "CHANGE", payload: { showFullPageLoader: false } });
		}
	};

	useEffect(() => {
		const fetchLoans = async () => {
			dispatch({ type: "CHANGE", payload: { loading: "FETCHING", data: [] } });

			try {
				const response = await getAllLoansCustomer();
				const result = response.data.data;
				console.log(result);

				if (result.length > 0) {
					const pages = Math.ceil(result.length / 10);
					const firstData = result.length > 10 ? result.slice(0, 10) : result;
					dispatch({
						type: "CHANGE",
						payload: { noOfPages: pages, store: result, data: firstData },
					});
				} else {
					dispatch({ type: "CHANGE", payload: { store: [], data: [] } });
				}
			} catch (error) {
				if (!error.response) {
					addToast("Kindly check your network and refresh", {
						appearance: "error",
						autoDismiss: true,
					});
				} else if (error.response) {
					addToast(error.response.data.errorMessage, {
						appearance: "error",
						autoDismiss: true,
					});
				} else {
					addToast("Couldn't fetch loans", {
						appearance: "error",
						autoDismiss: true,
					});
				}
			} finally {
				dispatch({ type: "CHANGE", payload: { loading: "RESOLVED" } });
			}
		};

		fetchLoans();
	}, [refetch]);

	return {
		allState,
		dispatch,
		prevPage,
		nextPage,
		refetchData,
		createDebitCard,
		fetchCurrentRepayment,
	};
};

export default useViewLoanCustomer;
